// ========= Styles for hamburger button icon =========
@use "../utilities/variables" as *;

.hamburger-button {
    $self: &;
    position: relative;
    
    width: 40px;
    height: 40px;
    
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    transition: transform 200ms ease-out;

    z-index: 2;

    @media screen and (min-width: 1024px) {
        display: none;
    }

    &--active {
        position: fixed;
        top: 20px;
        right: 20px;

        transform: rotate(90deg);
    }

    &__layer {
        display: block;
        position: absolute;
        right: 0;
        
        height: 5px;

        background-color: $dark-cornflower-blue;
        border-radius: 20px;
        transition: top 150ms 300ms ease-out,
                    bottom 150ms 300ms ease-out,
                    transform 150ms 300ms ease-out,
                    opacity 100ms 300ms ease-out,
                    background-color 100ms ease-out;

        .dark & {
            background-color: $white;
        }
        
        &:nth-child(1) {
            top: 5px;

            width: 80%;

            #{ $self }--active & {
                top: 50%;
                
                width: 100%;

                transform: translateY(-50%) rotate(45deg);
            }
        }

        &:nth-child(2) {
            top: 50%;

            width: 60%;

            transform: translateY(-50%);

            #{ $self }--active & {
                opacity: 0;
                transform: translateX(50%);
            }
        }

        &:nth-child(3) {
            bottom: 5px;

            width: 90%;

            #{ $self }--active & {
                bottom: 50%;

                width: 100%;

                transform: translateY(50%) rotate(-45deg);

            }
        }
    }
}
