// ============ Styles for services preview section in index.html ============
@use "../../utilities/variables" as *;

.main-informations {
    padding-bottom: 100px;

    transition: background-color 100ms ease-out;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dark & {
        background-color: $dark-cornflower-blue;
    }

    &__decorations-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        padding: 120px 0;

        overflow: hidden;

        @media screen and (min-width: 1024px) {
            flex-basis: 50%;
        }

        @media screen and (min-width: 1440px) {
            padding: 200px 0;
        }
    }

    &__circle {
        width: 350px;
        height: 350px;

        transform: translateX(-50%);
        border-radius: 50%;
        background-color: $dark-cornflower-blue;
        transition: background-color 100ms ease-out;
    
        @media screen and (min-width: 520px) {
            width: 500px;
            height: 500px;
        }

        @media screen and (min-width: 768px) {
            width: 700px;
            height: 700px;

            transform: translateX(-70%);
        }

        @media screen and (min-width: 1024px) {
            width: 580px;
            height: 580px;

            transform: translateX(-40%);
        }

        @media screen and (min-width: 1440px) {
            width: 800px;
            height: 800px;
        }

        .dark & {
            background-color: $usafa-blue;
        }
    }

    &__decoration-image {
        position: absolute;
        left: 30px;

        width: 260px;

        @media screen and (min-width: 520px) {
            width: 300px;

            left: 15%;
        }

        @media screen and (min-width: 768px) {
            width: 400px;            
        }

        @media screen and (min-width: 1440px) {
            width: 450px;

            left: 26%;
        }
    }

    &__information-content {
        padding: 0 10%;

        text-align: center;
        color: $dark-cornflower-blue;
   
        @media screen and (min-width: 768px) {
            padding: 0 20%;
        }

        @media screen and (min-width: 1024px) {
            flex-basis: 50%;

            padding: 0 10% 0 0;

            text-align: left;
        }

        @media screen and (min-width: 1440px) {
            padding: 0 20% 0 0;
        }

        .dark & {
            color: $white;
        }
    }

    &__title {
        margin-bottom: 35px;

        font-family: $roboto;
        line-height: 25px;
        color: $usafa-blue;

        @media screen and (min-width: 1440px) {
            font-size: 1.5em;
        }

        .dark & {
            color: $white;
        }
    }

    &__first-text-part {
        display: block;

        margin-bottom: 20px;

        font-size: 2em;
        font-family: $montserrat;
        
        &::first-letter {
            color: $mikado-yellow;
        }
    }

    &__text {
        margin-bottom: 25px;

        font-family: $roboto;
        line-height: 28px;
        font-size: 1.1em;
        text-align: justify;

        @media screen and (min-width: 1440px) {
            font-size: 1.3em;
            line-height: 35px;
        }

        &::first-letter {
            color: $mikado-yellow;
        }
    }
}
