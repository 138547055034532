@use "./variables" as *;

// Basic styles for project
* {
    padding: 0;
    margin: 0;

    box-sizing: border-box;
}

body {
    transition: background-color 100ms ease-out;
}

// Base styles for html
html {
    scroll-behavior: smooth;
}

// Styles for body (When mobile menu is active, disable scrolling)
.disable-scroll {
    overflow: hidden;
}

// Styles for body in dark mode
.dark {
    background-color: $dark-mode-blue;
}
