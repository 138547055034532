// ============ Styles for hero section in index.html ============
@use "../../utilities/variables" as *;

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    height: 500px;

    background-color: $dark-cornflower-blue;
    background-image: url("/src/images/index-hero-background-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 600px) {
        height: 550px;
    }

    @media screen and (min-width: 1024px) {
        justify-content: flex-start;

        background-image: url("/src/images/index-hero-background-desktop.png");
    }

    @media screen and (min-width: 1440px) {
        height: 600px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $dark-cornflower-blue;
        opacity: 0.8;
    }

    &__content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        width: 60%;

        text-align: center;
        color: $white;
    
        @media screen and (min-width: 600px) {
            width: 50%;
        }

        @media screen and (min-width: 1024px) {
            align-items: flex-start;
        
            margin-left: 6%;
        }

        @media screen and (min-width: 1200px) {
            margin-left: 15%;
        }

        @media screen and (min-width: 1440px) {
            margin-left: 25vw;
        }
    }

    &__title {
        margin-bottom: 10px;

        font-family: $montserrat;
        font-size: 3.4em;
        
        @media screen and (min-width: 600px) {
            font-size: 3.8em;
        }

        @media screen and (min-width: 1024px) {
            font-size: 4.5em;
        }

        @media screen and (min-width: 1440px) {
            font-size: 5em;
        }
    }

    &__subtitle {
        margin-bottom: 20px;

        font-family: $rubik;
        font-size: 1.1em;
        line-height: 30px;
        font-weight: 400;

        @media screen and (min-width: 600px) {
            font-size: 1.3em;
            line-height: 35px;
        }

        @media screen and (min-width: 1024px) {
            margin-bottom: 30px;
        }
    }

    &__main-button {
        padding: 12px 15px;

        border-radius: 8px;
        background-color: $mikado-yellow;
        box-shadow: 6px 6px $white;

        font-family: $roboto;
        color: $dark-cornflower-blue;
        font-size: 0.8em;
        font-weight: bold;
        letter-spacing: 1px;
        text-decoration: none;

        @media screen and (min-width: 1200px) {
            padding: 15px 18px;
        }
    }

    &__write-to-us-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;

        width: 80px;
        height: 80px;

        padding: 10px;

        background-color: $usafa-blue;

        text-decoration: none;
        color: $white;
        font-family: $rubik;
        text-align: center;
        font-size: 0.7em;

        @media screen and (min-width: 1024px) {
            width: 100px;
            height: 100px;
            
            padding: 15px;

            font-size: 0.8em;
        }
    }

    &__write-to-us-icon {
        width: 100%;
        height: auto;
        
        color: $white;
    }

    &__image {
        position: absolute;
        bottom: -110px;
        right: 20px;

        width: 200px;

        z-index: 1;

        @media screen and (min-width: 600px) {
            bottom: -130px;
            right: 30px;
            
            width: 280px;
        }

        @media screen and (min-width: 1024px) {
            bottom: -80px;
            
            width: 400px;
        }

        @media screen and (min-width: 1440px) {
            bottom: -120px;
            right: 10%;
            
            width: 500px;
        }

        @media screen and (min-width: 1800px) {
            right: 19vw;

            width: 550px;
        }
    }
}
