// ============ Styles for about us header section in o-nas.html ============
@use "../../utilities/variables" as *;

.about-us-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    height: 350px;

    background-color: $dark-cornflower-blue;
    background-image: url("/src/images/about-us-hero-background-mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1024px) {
        height: 450px;

        background-image: url("/src/images/about-us-hero-background-desktop.jpg");
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $dark-cornflower-blue;
        opacity: 0.8;
    }

    &__title {
        position: relative;

        color: $white;
        font-family: $montserrat;
        font-size: 3em;
    
        @media screen and (min-width: 1024px) {
            font-size: 4.5em;
        }
    }
}
