// ============ Styles for intro section in index.html ============
@use "../utilities/variables" as *;

.intro-section {
    position: relative;

    padding: 180px 0 100px 0; 

    transition: background-color 100ms ease-out;

    @media screen and (min-width: 650px) {
        padding: 220px 0 220px 0;       
    }

    .dark & {
        background-color: $dark-mode-blue;
    }

    &--no-extra-padding {
        padding: 100px 0;

        @media screen and (min-width: 650px) {
            padding: 220px 0 220px 0;       
        }
    }

    &__intro-text {
        width: 70%;

        margin: 0 auto;

        text-align: center;
        font-size: 1.3em;
        font-family: $rubik;
        color: $dark-cornflower-blue;

        @media screen and (min-width: 1024px) {
            position: relative;

            font-size: 1.6em;

            z-index: 2;
        }

        @media screen and (min-width: 1440px) {
            font-size: 1.8em;
        }

        .dark & {
            color: $white;
        }
    }

    &__first-text-part {
        display: block;

        margin-bottom: 10px;

        font-size: 1.5em;
        font-family: $montserrat;
        color: $usafa-blue;

        .dark & {
            color: $white;
        }
    }

    &__highlight-text {
        color: $mikado-yellow;
    }

    &__decorations-container {
        position: absolute;
        top: 0;
        
        width: 100%;
        height: 100%;
    
        z-index: 1;
    }

    & .decoration-gear {
        &:nth-child(1) {
            width: 200px;
            height: 200px;

            top: 100px;
            left: 12%;

            @media screen and (max-width: 1024px) {
                top: 30px;
                left: 5%;
            }
        }

        &:nth-child(2) {
            width: 150px;
            height: 150px;

            top: 250px;
            right: 10%;
        }
    }
}
