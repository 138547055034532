// ====== Variables ======

// === Colors ===
$dark-cornflower-blue: #003f88ff;
$usafa-blue: #00509dff;
$dark-mode-blue: rgb(5, 70, 145);
$mikado-yellow: #fdc500ff;
$cyber-yellow: #ffd500ff;
$white: #ffff;

// === Fonts ===
$montserrat: 'Montserrat', sans-serif;
$roboto: 'Roboto', sans-serif;
$rubik: 'Rubik', sans-serif;
