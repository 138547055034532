// ========= Styles for footer section component =========
@use "../utilities/variables" as *;

.footer-section {
    // === Styles for pros part ===
    &__pros-part {
        position: relative;

        padding: 100px 30px;

        background-color: $dark-cornflower-blue;
        background-image: url("/src/images/footer-banner-mobile.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        font-family: $montserrat;
        color: $white;

        @media screen and (min-width: 768px) {
            padding: 12% 30px;
        }

        @media screen and (min-width: 1024px) {
            background-image: url("/src/images/footer-banner-desktop.png");
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            
            width: 100%;
            height: 100%;

            background-color: $dark-cornflower-blue;
            background: linear-gradient(180deg, rgba($dark-cornflower-blue, 0.7) 0%, rgba($dark-cornflower-blue, 1) 85%);
        }
    }

    &__pros-title {
        position: relative;

        margin-bottom: 100px;

        text-align: center;
        font-size: 2em;
        line-height: 45px;

        @media screen and (min-width: 768px) {
            margin-bottom: 20px;
        
            font-size: 2.5em;
        }

        @media screen and (min-width: 1024px) {
            margin-bottom: 30px;
            
            font-size: 3.5em;
        }

        &::first-letter {
            color: $mikado-yellow;
        }
    }

    &__pros {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
    
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }

    &__plus {
        margin-bottom: 30px;

        font-size: 1.4em;

        @media screen and (min-width: 768px) {
            margin: 0 20px;

            font-size: 1em;
        }

        @media screen and (min-width: 1024px) {
            font-size: 1.3em;
        }
    }

    // === Styles for contact part ===
    &__contact-part {
        padding: 50px 45px;

        background-color: $dark-cornflower-blue;
        
        @media screen and (min-width: 600px) {
            padding: 50px 20%;
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            justify-content: center;

            padding: 30px 0 10% 0;
        }
    }   
    
    &__contact-block {
        margin-bottom: 45px;

        font-family: $roboto;
        color: $white;
        font-weight: 500;

        @media screen and (min-width: 1024px) {
            margin: 0 5%;

            &:nth-child(1),
            &:nth-child(2) {
                width: 300px;
            }

            &:nth-child(3) {
                width: 450px;
            }
        }

        @media screen and (min-width: 1440px) {
            margin: 0 2%;
        }
    }

    &__block-title {
        padding: 10px 0;
        margin-bottom: 35px;

        border-bottom: solid 4px $mikado-yellow;

        color: $mikado-yellow;
        font-family: $roboto;
        font-size: 1.8em;
        letter-spacing: 1px;
    }

    &__contact-info {
        padding: 20px 0;

        @media screen and (min-width: 1024px) {
            font-size: 1.1em;
        }
    }

    &__see-link {
        display: block;

        padding: 15px 0;

        text-decoration: none;
        color: $white;
    
        @media screen and (min-width: 1024px) {
            font-size: 1.1em;
        }
    }

    // Simple write to us form
    &__input {
        width: 100%;

        padding: 18px 25px;
        margin-bottom: 30px;

        border-radius: 5px;
        border: 0;
        outline: none;

        font-size: 1em;
        font-family: $roboto;
        color: $dark-cornflower-blue;
        font-weight: 500;

        &::placeholder {
            font-family: $roboto;
            color: rgba($dark-cornflower-blue, 0.5);
            font-weight: 500;
        }
    }

    &__input-wrapper {
        position: relative;
    }

    &__input-decoration-image {
        position: absolute;
        bottom: 5px;
        right: -35px;
        
        width: 120px;
        height: auto;

        @media screen and (min-width: 768px) {
            bottom: -10px;
            right: -50px;
            
            width: 160px;
        }
    }

    &__submit-button {
        padding: 15px 40px;

        background-color: $mikado-yellow;
        border-radius: 5px;
        border: 0;
        outline: none;
        box-shadow: 8px 8px $white;
    
        font-family: $rubik;
        font-weight: 500;
        font-size: 1.1em;
        color: $dark-cornflower-blue;
    }

    // Copyright part
    &__copyright-bar {
        padding: 40px 30px;

        background-color: $usafa-blue;

        font-family: $roboto;
        text-align: center;
        line-height: 30px;
        color: $white;
        font-size: 1em;

        @media screen and (min-width: 1024px) {
            text-align: left;
        }

        @media screen and (min-width: 1440px) {
            padding: 40px 10%;
        }
    }
}
