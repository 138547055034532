// ========= Styles for navigation bar =========
@use "../utilities/variables" as *;

.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;    

    padding: 20px;

    transition: top 200ms ease-out,
                background-color 100ms ease-out;
    background-color: $white;

    z-index: 2;

    @media screen and (min-width: 1024px) {
        padding: 0 0 0 100px;
    }

    .dark & {
        background-color: $dark-cornflower-blue;
    }

    &--hide {
        top: -80px;

        @media screen and (min-width: 1024px) {
            top: -101px;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        margin-right: 40px;

        @media screen and (min-width: 1024px) {
            margin-right: 80px;
        }
    }

    &__logo-link {
        text-decoration: none;
        font-family: $rubik;
        font-weight: 700;
        font-size: 1.6em;
        color: $dark-cornflower-blue;

        @media screen and (min-width: 1024px) {
            font-size: 2.2em;
        }

        .dark & {
            color: $white;
        }
    }

    &__logo-part {
        color: $mikado-yellow;
    }

    &__color-changer {
        position: relative;

        width: 40px;
        height: 20px;

        border-radius: 25px;
        border: solid 1px $dark-cornflower-blue;
        box-sizing: content-box;
        outline: none;
        background-color: $dark-cornflower-blue;
        transform: scale(0.8);
        cursor: pointer;
        transition: background-color 100ms ease-out;

        @media screen and (min-width: 1024px) {
            transform: scale(1.2);
        }

        .dark & {
            background-color: $white;
        }
    
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;

            width: 18px;
            height: 18px;

            transform: translateY(-50%);
            border-radius: 50%;
            background-color: $white;
            transition: transform 180ms ease-out,
                        background-color 100ms ease-out;
        
            .dark & {
                background-color: $usafa-blue;
            }
        }

        &--active::before {
            transform: translate(-100%, -50%);
        }
    }

    &__mode-icon {
        position: absolute;
        top: 50%;

        width: 15px;
        height: 15px;

        transform: translateY(-50%);

        &--sun {
            right: -25px;

            color: $mikado-yellow;
        }

        &--mood {
            left: -25px;

            color: $dark-cornflower-blue;

            .dark & {
                color: $white;
            }
        }
    }

    &__links-container {
        position: fixed;
        top: 0;
        right: 0;

        width: 100%;
        height: 100%;

        padding: 110px 0;

        background-color: $white;
        transform: translateX(100%);
        transition: transform 600ms cubic-bezier(.79,.11,0,.71),
                    background-color 100ms ease-out;

        z-index: 1;

        @media screen and (min-width: 600px) {
            width: 50%;

            box-shadow: -5px 0 12px rgba($dark-cornflower-blue, 0.1);
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            position: static;
            
            width: auto;
            height: auto;

            padding: 0;
            margin-right: 10%;

            box-shadow: none;

            transform: translateX(0);
        }

        .dark & {
            background-color: $dark-cornflower-blue;
        }

        &--active {
            transform: translateX(0);
        }
    }

    &__link {
        display: block;

        padding: 20px 60px;

        border-bottom: dashed 2px rgba($usafa-blue, 0.1);

        letter-spacing: 1px;
        text-decoration: none;
        font-family: $rubik;
        color: $dark-cornflower-blue;
        font-weight: 500;

        @media screen and (min-width: 1024px) {
            padding: 40px 30px;

            border: 0;
        }

        .dark & {
            border-color: rgba($white, 0.1);

            color: $white;
        }

        &:first-child {
            border-top: dashed 2px rgba($usafa-blue, 0.1);

            @media screen and (min-width: 1024px) {
                border-top: 0;
            }
        
            .dark & {
                border-color: rgba($white, 0.1);
            }
        }

        &--special {
            @media screen and (min-width: 1024px) {
                background-color: $mikado-yellow;
            
                .dark & {
                    color: $dark-cornflower-blue;
                }
            }
        }
    }
}
