// ============ Styles for who we are section in o-nas.html ============
@use "../../utilities/variables" as *;

.who-we-are-section {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 70px 0;

    transition: background-color 100ms ease-out;

    @media screen and (min-width: 768px) {
        flex-direction: row;

        padding: 90px 5%;
    }

    @media screen and (min-width: 1200px) {
        padding: 90px 15%;
    }

    @media screen and (min-width: 1440px) {
        padding: 10% 15%;
    }

    @media screen and (min-width: 1600px) {
        padding: 10% 20%;
    }

    .dark & {
        background-color: $dark-mode-blue;
    }

    &__content {
        padding: 0 10%;
        margin-bottom: 35px;

        @media screen and (min-width: 768px) {
            padding: 0 6%;

            margin: 0 25px 0 0;
        }
    }

    &__title {
        margin-bottom: 25px;

        color: $dark-cornflower-blue;
        font-family: $montserrat;
        font-size: 1.8em;
        text-align: center;

        @media screen and (min-width: 768px) {
            text-align: left;
        }

        @media screen and (min-width: 1200px) {
            font-size: 2.2em;
        }

        .dark & {
            color: $mikado-yellow;
        }
    }

    &__text {
        color: $dark-cornflower-blue;
        text-align: justify;
        font-family: $rubik;
        line-height: 25px;
        text-align: justify;

        @media screen and (min-width: 768px) {
            line-height: 27px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 1.2em;
            line-height: 30px;
        }

        .dark & {
            color: $white;
        }
    }

    &__service-icon {
        width: 250px;
        height: auto;

        @media screen and (min-width: 1440px) {
            width: 350px;
        }
    }
}
