// ========= Styles for decoration gear component =========
@use "../utilities/variables" as *;

.decoration-gear {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    width: 100px;
    height: 100px;

    border-radius: 50%;
    animation: spinning 2.5s linear infinite;
    background-color: $white;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    .dark & {
        background-color: $dark-mode-blue;
    }

    &__icon {
        width: 70%;
        height: auto;
    }
}

@keyframes spinning {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
