// ============ Styles for services preview section in index.html ============
@use "../../utilities/variables" as *;

.services-preview {
    $self: &;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);

    @media screen and (min-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    &__preview-card {
        @media screen and (min-width: 1440px) {
            padding-top: 15%;   
        }
        
        &--light-blue {
            background-color: $usafa-blue;
        }

        &--dark-blue {
            background-color: $dark-cornflower-blue;
        }

        &--light {
            background-color: rgb(238, 238, 238);
        }

        &--yellow {
            background-color: $mikado-yellow;
        }

        &--light,
        &--yellow {
            #{ $self }__card-content {
                color: $dark-cornflower-blue;
            }
        }
    }

    &__icon-wrapper {
        padding: 40px;

        text-align: center;
    }

    &__icon {
        width: 100px;
        height: auto;
        
        @media screen and (min-width: 1440px) {
               
        }
    }

    &__card-content {
        padding: 0 15% 10% 15%;

        text-align: center;
        font-family: $roboto;
        color: $white;
    
        @media screen and (min-width: 600px) {
            padding: 0 20% 12% 20%;
        }

        @media screen and (min-width: 1440px) {
            padding: 0 25% 15% 25%;   
        }
    }

    &__card-title {
        margin-bottom: 20px;

        line-height: 25px;
        
        @media screen and (min-width: 1440px) {
            font-size: 1.3em;
            line-height: 30px;   
        }
    }

    &__card-content-text {
        line-height: 25px;
    }
}
