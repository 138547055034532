// ============ Styles for services details section in uslugi.html ============
@use "../../utilities/variables" as *;

.services-details-section {
    $self: &;

    transition: background-color 100ms ease-out;

    .dark & {
        background-color: $dark-mode-blue;
    }

    &__service-card {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
            flex-direction: row;
        }

        &--reverse {
            @media screen and (min-width: 768px) {
                flex-direction: row-reverse;
            }

            #{ $self }__decoration {
                left: auto;
                right: -60px;
            }
        }

        &--margin-card {
            @media screen and (min-width: 1024px) {
                margin: 12% 0;
            }
        }

        &--yellow {
            #{ $self }__card-content {
                background-color: $mikado-yellow;
            
                .dark & {
                    background-color: $mikado-yellow;

                    color: $dark-cornflower-blue;
                }
            }

            #{ $self }__card-title {
                .dark & {
                    color: $dark-cornflower-blue;
                }
            }
        }

        &--blue {
            #{ $self }__card-content {
                background-color: $usafa-blue;

                color: $white;

                .dark & {
                    background-color: $usafa-blue;
                
                    color: $white;
                }
            }
        }
    }

    &__card-image {
        height: 300px;

        background-color: $dark-cornflower-blue;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media screen and (min-width: 600px) {
            height: 400px;
        }

        @media screen and (min-width: 768px) {
            flex-basis: 35%;

            height: auto;
        }

        @media screen and (min-width: 1600px) {
            flex-basis: 40%;
        }
    }

    &__card-content {
        position: relative;

        padding: 18% 10%;

        background-color: $white;
        transition: background-color 100ms ease-out;

        color: $dark-cornflower-blue;
        text-align: center;

        @media screen and (min-width: 768px) {
            flex-basis: 65%;

            padding: 8%;

            text-align: left;
        }

        @media screen and (min-width: 1440px) {
            padding: 5% 8%;
        }

        @media screen and (min-width: 1600px) {
            flex-basis: 60%;
            
            padding: 5% 12%;
        }

        .dark & {
            background-color: $dark-mode-blue;

            color: $white;
        }
    }

    &__decoration {
        left: -60px;
        bottom: -60px;

        width: 120px;
        height: 120px;
    }

    &__card-title {
        margin-bottom: 40px;
        
        font-family: $rubik;
        font-size: 1.6em;
        line-height: 35px;
        font-weight: 500;

        @media screen and (min-width: 1024px) {
            font-size: 2em;
            line-height: 45px;
        }

        .dark & {
            color: $mikado-yellow;
        }
    }

    &__card-text {
        margin-bottom: 40px;

        font-family: $roboto;
        line-height: 28px;
        font-size: 1.1em;
        text-align: justify;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
